<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    bottom
    left
    rounded="pill"
    :color="getColor"
    elevation="24"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false" rounded>
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { eventBus } from "../../main";

export default {
  name: "SnackBarService",
  data: () => ({
    snackbar: false,
    success: true,
    message: "This is a message",
    timeout: 3000,
  }),
  computed: {
    getColor() {
      switch (this.success) {
        case true:
          return "success";
        case false:
          return "error";
        default:
          console.log(`Something happened on MessageService`);
          return "error";
      }
    },
  },
  created() {
    eventBus.$on("showSnackBar", (success, message, timeout) => {
      this.success = success;
      this.message = message;
      this.timeout = timeout;
      this.snackbar = true;
    });
  },
};
</script>
