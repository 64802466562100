<template>
  <v-app>
    <SnackBarService />
    <!-- <AppNavBar :hideButtons="hideButtons"></AppNavBar> -->
    <v-main class="grey lighten-4" v-if="loaded">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import AppNavBar from "./components/Header/AppNavBar/AppNavBar.vue";
import SnackBarService from "./components/Shared/SnackBarService.vue";

export default {
  components: {
    // AppNavBar,
    SnackBarService,
  },
  name: "App",

  data: () => ({
    loaded: false,
    //
  }),
  computed: {
    hideButtons() {
      return this.$route.name === "getting-started";
    },
  },
  created() {
    if (!this.$store.getters.userData) {
      window.addEventListener("UserDataLoaded", () => {
        this.$store.dispatch("updateUserDataFromCookie");

        this.loaded = true;
      });
    } else {
      this.$store.dispatch("updateUserDataFromCookie");
      this.loaded = true;
    }
  },
};
</script>
