import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/user-management",
    redirect: { name: "verified-users" },
  },
  {
    path: "/user-management/verified-users",
    name: "verified-users",
    component: () => import("../views/VerifiedUsersView.vue"),
  },
  {
    path: "/user-management/unverified-users",
    name: "unverified-users",
    component: () => import("../views/UnverifiedUsersView.vue"),
  },
  {
    path: "/user-management/getting-started",
    name: "getting-started",
    component: () => import("../views/GettingStartedView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (
//     to.path !== "/" &&
//     to.path !== "/user-management/getting-started" &&
//     !Vue.prototype.$msal.isSupraUser()
//   ) {
//     next("/");
//   } else {
//     next();
//   }
// });

export default router;
