import Vue from "vue";
import Vuex from "vuex";
// import CryptoJS from "crypto-js";
import VueCookies from "vue-cookies";

Vue.use(Vuex);
// Vue.use(VueCookies);
// Funciones para encriptar y desencriptar
// const ENCRYPTION_KEY = process.env.VUE_APP_ENCRIPTION_KEY; // Cambia esto a tu clave de encriptación

function decryptData(encryptedData) {
  if (!encryptedData) return null;
  // const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
  // return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return encryptedData;
}

export default new Vuex.Store({
  state: {
    isAdmin: false,
    userData: decryptData(VueCookies.get("sessionCosmos")) || null,
  },
  getters: {
    isAdmin: (state) => state.isAdmin,
    userID: (state) => state.userData.id,
    userData: (state) => state.userData,
  },
  mutations: {
    setAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setUserID(state, userID) {
      state.userID = userID;
    },
    setUserData(state, userData) {
      const decryptedData = decryptData(userData);
      state.userData = decryptedData;
    },
    // deleteUserData(state) {
    //   localStorage.removeItem("userData");
    //   state.userData = {};
    // },
  },
  actions: {
    updateUserDataFromCookie({ commit }) {
      const sessionCosmosData = VueCookies.get("sessionCosmos");
      if (sessionCosmosData) {
        // Realiza cualquier manipulación adicional necesaria
        // Por ejemplo, decodificar o parsear los datos
        // const userData = JSON.parse(sessionCosmosData);

        // Hacer commit de los datos al store Vuex
        commit("setUserData", sessionCosmosData);
      } else {
        commit("setUserData", null);
      }
    },
  },
  modules: {},
});
